
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import roc_list, {
  business_natures,
  group_types,
  industry_types_pc,
  business_types,
} from "@/core/data/genericData";
import axios from "axios";
import { mask } from "vue-the-mask";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
import JwtService from "@/core/services/JwtService";
import { ElTable } from "element-plus";
import AddProductDetailsModal from "@/components/modals/forms/AddProductDetailsModal.vue";
import moment from "moment";
import { getIn } from "yup/lib/util/reach";

// import DataTable from "@/components/custom/Table.vue";

export default defineComponent({
  name: "add-client-modal",
  directives: { mask },
  components: {
    AddProductDetailsModal,

    // DataTable,
  },

  setup(props, { emit }) {
    const store = useStore();
    // const nob_data = business_natures;
    // const group_type_data = group_types;
    // const business_types_data = business_types;
    // const roc_list_data = roc_list;
    const supplier_data = ref<Array<Supplier>>([]);
    const expeceted_delivery_data = ref([
      {
        id: 1,
        name: "15 Days",
      },
      {
        id: 2,
        name: "20 Days",
      },
      {
        id: 3,
        name: "25 Days",
      },
      {
        id: 4,
        name: "30 Days",
      },
      {
        id: 4,
        name: "45 Days",
      },
      {
        id: 5,
        name: "60 Days",
      },
      {
        id: 6,
        name: "70 Days",
      },
      {
        id: 7,
        name: "75 Days",
      },
      {
        id: 8,
        name: "90 Days",
      },
      {
        id: 9,
        name: "Immediatly",
      },
    ]);
    interface Supplier {
      company_id: number;
      company_name: string;
      company_name_search: string;
      company_name_alternative: string;
      company_nob_id: number;
      company_nob_name: string;
      company_group_type_id: number;
      company_group_type_name: number;
      company_industry_type_id: number;
      company_industry_type_name: string;
      company_business_type_id: number;
      company_business_type_name: string;
      company_roc_id: number;
      company_roc_name: string;
      company_doi: string;
      company_cin_llpin: string;
      company_registration_number: string;
      company_pan: string;
      company_website: string;
      company_verified_yes_no: number;
      company_trade_type_id: number;
      active: number;
    }

    const group_type_data = ref([]);
    var paginationData = ref({});

    paginationData.value = {
      start: 0,
      end: 0,
      total: 0,
      perPage: 5,
      activePage: 1,
      totPage: 1,
      mmaxPageg: 6,
    };
    const nob_data = ref([]);
    const business_types_data = ref([]);
    const roc_list_data = ref([]);
    const uom_data = ref([]);
    const inward_warehouse_data = ref([]);
    const outward_warehouse_data = ref([]);
    // const tableData = ref([]);
    var tableData = ref<Array<WIJournal>>([]);
    // const addressData = ref<Array<tableDataRefI>>([]);

    const branch_data = ref([]);
    const dialogSelectLocationTableVisible = ref(false);
    const multipleTableRef = ref<InstanceType<typeof ElTable>>();
    const multipleSelection = ref<WIJournal[]>([]);
    const addressList = ref<Address[]>([]);
    const type_data = ref([
      {
        id: 1,
        name: "Credit",
      },
      {
        id: 2,
        name: "Debit",
      },
    ]);

    interface tableDataRefI {
      Product;
      Quantity;
      Batch_no;
      UOM;
      Rate;
    }
    interface Address {
      pincode: string;
      city: string;
      state: string;
    }

    const product_data = ref([
      {
        product_id: 2,
        product_name: "AMNS PPGI Coil IS-2062 E250A",
        product_short_name: "AM/NS PPGI Coil IS-2062 E250A",
      },
      {
        product_id: 1,
        product_name: "JSW PPGI Coil IS-2062 E250 BR",
        product_short_name: "JSW PPGI Coil IS-2062 E250 BR",
      },
    ]);

    const incoterms_data = ref([]);
    const payment_term_data = ref([]);
    const invoice_no_data = ref([]);

    const reason_data = ref([
      {
        id: 1,
        name: "Amount Difference",
      },
      {
        id: 2,
        name: "Material Return",
      },
      {
        id: 3,
        name: "Qty Difference",
      },
      {
        id: 4,
        name: "Rate Difference",
      },
      {
        id: 5,
        name: "Other",
      },
    ]);

    // const inward_warehouse_data = ref([
    //   {
    //     id: 1,
    //     name: "Warehouse 1",
    //   },
    //   {
    //     id: 2,
    //     name: "Warehouse 2",
    //   },
    // ]);

    // const outward_warehouse_data = ref([
    //   {
    //     id: 1,
    //     name: "Warehouse 1",
    //   },
    //   {
    //     id: 2,
    //     name: "Warehouse 2",
    //   },
    // ]);

    const transport_applicable_data = ref([
      {
        id: 0,
        name: "No",
      },
      {
        id: 1,
        name: "Yes",
      },
    ]);

    const industry_types_data = industry_types_pc;
    const formRef = ref<null | HTMLFormElement>(null);
    const addStockJournalVoucharRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const router = useRouter();

    getAddData();
    getUOM();
    getIncoterms();
    getPaymentTerms();
    getCompanyList();
    getProductList();
    getWarehouseList();

    interface WIJournal {
      sales_invoice_details_id: string;
      product_name: string;
      product_qunatity: string;
      product_billing_qunatity: string;
      uom: string;
      product_rate: string;
      amount: string;
    }
    // console.log("industry_types_data");console.log(industry_types_data);

    // interface Iind_ty_pc  {
    //   industry_type_name,
    //   industry_type_id,
    //   parent_industry_id
    // }

    // var industry_types_data = ref<Array<Iind_ty_pc>>([]);
    // var ind_ty_pcT = ref<Array<Iind_ty_pc>>([])
    // var k =  0;

    // for (let j = 0; j < industry_types_pc.length; j++) {

    //     ind_ty_pcT.value = Array({
    //       industry_type_name : industry_types_pc[j]['company_industry_type_name'],
    //       industry_type_id : industry_types_pc[j]['company_industry_type_id'],
    //       parent_industry_id : 0
    //     });

    //   industry_types_data.value.splice(k, ind_ty_pcT.value.length, ...ind_ty_pcT.value);

    //   if(industry_types_pc[j]['subtype']){

    //     console.log(industry_types_pc[j]['subtype']);

    //     for (let i = 0; i < industry_types_pc[j]['subtype'].length; i++) {
    //       ind_ty_pcT.value = Array({
    //         industry_type_name :  industry_types_pc[j]['subtype'][i]['company_industry_type_name'],
    //         industry_type_id :  industry_types_pc[j]['subtype'][i]['company_industry_type_id'],
    //         parent_industry_id : industry_types_pc[j]['subtype'][i]['company_industry_type_id'],
    //       });
    //       k = k+1;
    //       industry_types_data.value.splice(k, ind_ty_pcT.value.length, ...ind_ty_pcT.value);
    //     }

    //   }
    //   k = k+1;
    // }

    function deleteRow(row) {
      myData.value.splice(row, 1);
    }

    const formData = ref({
      type_select: 1,
      client_select: "",
      reason_select: 2,
      invoice_no_select: "",
    });

    const myData = ref([
      {
        product: "",
        quantity: "",
        remark: "",
      },
    ]);

    const addressData = ref([
      {
        pincode: "",
        city: "",
        state: "",
      },
    ]);

    const rules = ref({
      client_select: [
        {
          required: true,
          message: "Client is required",
          trigger: "change",
        },
      ],
      reason_select: [
        {
          required: true,
          message: "Reason is required",
          trigger: "change",
        },
      ],
      invoice_no_select: [
        {
          required: true,
          message: "Invoice No is required",
          trigger: "change",
        },
      ],
    });

    const setCompanyData = async (data) => {
      // let product_list = [];
      debugger;
      let product_list = multipleSelection.value.map((item) => {
        return {
          sales_invoice_details_id: item.sales_invoice_details_id,
          product_qunatity: item.product_billing_qunatity,
        };
      });
      let validateProductList = multipleSelection.value.filter((item) => {
        return item.product_billing_qunatity != "";
      });
      if (
        validateProductList === [] ||
        validateProductList.length != multipleSelection.value.length
      ) {
        console.log("validation failed");
        Swal.fire({
          text: "Please Fill Return Quantity in Selected Products!",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        loading.value = false;
        return;
      }
      if (multipleSelection.value.length <= 0) {
        console.log("validation failed");
        Swal.fire({
          text: "Please Select At Least One Product.",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        loading.value = false;
        return;
      }
      const db_data = {
        buyer_company_id: data.client_select,
        note_type: data.type_select,
        crdr_reason_id: data.type_select === "" ? 0 : data.type_select,
        sales_invoice_id: data.invoice_no_select,
        product_data: product_list,
      };
      console.log(JSON.stringify(db_data));
      try {
        await axios
          .post(
            "https://elogicalservices.mymetal.in/sd_crdr_note/create",
            db_data,
            {
              headers: {
                "x-api-key": "4HTvrHxuh98GvP3XWYXaUYMHgDnOuMa1aHXtDD95",
              },
            }
          )
          .then(({ data }) => {
            loading.value = false;
            console.log(data);
            setTimeout(() => {
              loading.value = false;

              Swal.fire({
                text: "Form has been successfully submitted!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addStockJournalVoucharRef.value);
                emit("refreshList");
              });
            }, 2000);
          })
          .catch(({ response }) => {
            loading.value = false;
            console.log(response);
            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          });
      } catch (e) {
        loading.value = false;
        console.log(e);
      }
    };

    // const setCompanyData = async (data) => {
    //   var user = JSON.parse(JwtService.getToken());

    //   var dt = ("0" + data.incorporationateDate.getDate()).slice(-2);
    //   var mo = ("0" + (data.incorporationateDate.getMonth() + 1)).slice(-2);
    //   var yy = data.incorporationateDate.getFullYear();
    //   var dtt = yy + "-" + mo + "-" + dt;
    //   var cinLlpin = data.llpinn === "" ? data.cinn : data.llpinn;

    //   const db_data = {
    //     company_name: data.name,
    //     company_name_alternative: data.alternativename,
    //     company_nob: data.nob_select,
    //     company_group_type: data.group_type_select,
    //     company_industry_type: data.industry_type_select,
    //     company_business_type: data.business_type_select,
    //     company_roc: data.roc_select === "" ? 0 : data.roc_select,
    //     company_doi: dtt,
    //     company_cin_llpin: cinLlpin === "" ? 0 : cinLlpin,
    //     company_registration_number:
    //       data.registrationNo === "" ? 0 : data.registrationNo,
    //     company_pan: data.pan,
    //     company_website: data.website,
    //     company_verified_yes_no: false,
    //     active: true,
    //     created_user_id: user.user_id,
    //   };

    //   console.log(db_data);

    //   await store
    //     .dispatch(Actions.CUST_ADD_COMPANY, db_data)
    //     .then(({ data }) => {
    //       console.log(data);

    //       if (data.company_id) {
    //         setTimeout(() => {
    //           loading.value = false;
    //           //
    //           Swal.fire({
    //             text: "Company has been successfully created.",
    //             icon: "success",
    //             buttonsStyling: false,
    //             confirmButtonText: "Ok, got it!",
    //             customClass: {
    //               confirmButton: "btn btn-primary",
    //             },
    //           }).then(() => {
    //             hideModal(addCompanyModalRef.value);
    //             router.go(0);
    //           });
    //         }, 2000);
    //       } else {
    //         loading.value = false;

    //         Swal.fire({
    //           text: "Sorry, looks like there are some errors detected, please try again.",
    //           icon: "error",
    //           buttonsStyling: false,
    //           confirmButtonText: "Ok, got it!",
    //           customClass: {
    //             confirmButton: "btn btn-primary",
    //           },
    //         });
    //         return false;
    //       }
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);
    //     });
    // };

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setCompanyData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    async function getUOM() {
      await store
        .dispatch(ActionsFi.CUST_GET_UOM)
        .then(({ data }) => {
          uom_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }
    async function getInvoiceData() {
      let params = {
        company_id: formData.value["client_select"],
      };
      await store
        .dispatch(ActionsFi.CUST_GET_SALES_INVOICE_BY_ID_LIST, params)
        .then(({ data }) => {
          if (!data || data?.length <= 0) {
            invoice_no_data.value = [];
          } else {
            invoice_no_data.value = data;
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }
    function Required() {
      return { color: "red" };
    }
    async function getIncoterms() {
      await store
        .dispatch(ActionsFi.CUST_GET_INCOTERMS_DATA)
        .then(({ data }) => {
          incoterms_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getPaymentTerms() {
      await store
        .dispatch(ActionsFi.CUST_GET_PAYMENT_TERMS_DATA)
        .then(({ data }) => {
          debugger;
          payment_term_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const handleSelectionChange = (val: WIJournal[]) => {
      debugger;
      let newVal = [...val];
      // newVal.forEach((row) => {
      //   row.cellDisabled = !row.cellDisabled;
      //   return row;
      // });
      multipleSelection.value = newVal;

      // let index = val.length != 0 ? val.length - 1 : 0;
      // var rowValue = val[index];
      // multipleSelection.value = [];
      // multipleSelection.value.push(rowValue);
      // val.forEach((row) => {
      //   multipleTableRef.value!.toggleRowSelection(row, true);
      // });
    };

    const onCancle = () => {
      multipleTableRef.value!.clearSelection();
      multipleSelection.value = [];
      dialogSelectLocationTableVisible.value = false;
    };

    async function getCompanyList() {
      let values = { trade_type: 1 };
      await store
        .dispatch(Actions.CUST_GET_SUPPLIER_LIST, values)
        .then(({ data }) => {
          debugger;
          supplier_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const getTableData = async (data) => {
      try {
        var values = {
          sales_invoice_id: formData.value["invoice_no_select"],
        };
        console.log(values);
        await store
          .dispatch(ActionsFi.CUST_GET_SALES_INVOICE_BY_ID_LISTING_DATA, values)
          .then((data) => {
            debugger;
            console.log("total data:" + JSON.stringify(data));

            debugger;
            // data.data = data.data.map((item) => {
            //   let typeValue = type_data.value.filter((type) => {
            //     return (
            //       type.stock_product_type_id === item.stock_product_type_id
            //     );
            //   });
            //   item.stock_product_type = typeValue[0]?.stock_product_type;
            //   return item;
            // });
            tableData.value = [];
            const totalCount = data.data.total_records;
            // set pagination
            paginationData.value["total"] = totalCount;
            data = data.data;
            var pg =
              paginationData.value["activePage"] *
              paginationData.value["perPage"];
            paginationData.value["start"] =
              pg - paginationData.value["perPage"];
            paginationData.value["end"] =
              paginationData.value["start"] +
              paginationData.value["perPage"] * 1;

            paginationData.value["totPage"] = Math.ceil(
              totalCount / paginationData.value["perPage"]
            );

            paginationData.value["mmaxPageg"] =
              paginationData.value["totPage"] <
              paginationData.value["mmaxPageg"]
                ? paginationData.value["totPage"]
                : paginationData.value["mmaxPageg"];

            var resultsM = ref<Array<WIJournal>>([]);
            var status_label = "";
            var status_color = "";
            var active_label = "";
            var active_color = "";

            for (let j = 0; j < data.length; j++) {
              let amount =
                parseFloat(data[j]["product_rate"]) *
                parseFloat(data[j]["product_billing_qunatity"]);
              resultsM.value = Array({
                sales_invoice_details_id: data[j]["sales_invoice_details_id"],
                sales_invoice_no: data[j]["sales_invoice_no"],
                product_name: data[j]["product_name"],
                product_qunatity: data[j]["product_billing_qunatity"],
                product_billing_qunatity: "0",
                uom:
                  "Qty:" +
                  data[j]["product_billing_qunatity"] +
                  "   " +
                  data[j]["uom"] +
                  "    " +
                  "Rate:" +
                  data[j]["product_rate"],
                product_rate: data[j]["product_rate"],
                amount: amount + "",
              });
              console.log("resultsm:" + JSON.stringify(resultsM.value));
              tableData.value.splice(
                j,
                resultsM.value.length,
                ...resultsM.value
              );
            }
          })
          .catch(({ response }) => {
            tableData.value = [];
          });
      } catch (e) {
        console.log(e);
      }
    };

    async function getProductList() {
      var values = { search_term: "", company_id: 0 };
      await store
        .dispatch(Actions.CUST_GET_PRODUCT_DROPDOWN_LIST, values)
        .then(({ data }) => {
          debugger;
          product_data.value = data;
          // console.log(JSON.stringify(data.data.result_list));
          // console.log(JSON.stringify(product_data.value));
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    function logReason() {
      console.log("reason is..." + formData.value["reason_select"]);
    }

    function addProduct() {
      // tableData.value.push({
      //   Product: "test",
      //   Quantity: "3",
      //   Rate: "4",
      //   Batch_no: "3",
      //   UOM: "kg",
      // });
      // myData.value.push({
      //   product: "",
      //   quantity: "",
      //   remark: "",
      // });
      //   console.log("table data.." + tableData.value);
    }

    async function getWarehouseList() {
      let values = {
        company_id: "1",
        // page: 1,
        // records_per_page: 10,
      };
      await store
        .dispatch(Actions.CUST_GET_WAREHOUSE_LIST, values)
        .then(({ data }) => {
          debugger;
          inward_warehouse_data.value = data;
          outward_warehouse_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const openDilogue = () => {
      // if (formData.value["type_select"] === "") {
      //   Swal.fire({
      //     text: "Please select purchase requisition type first!",
      //     icon: "error",
      //     buttonsStyling: false,
      //     confirmButtonText: "Ok, got it!",
      //     customClass: {
      //       confirmButton: "btn btn-primary",
      //     },
      //   });
      //   return false;
      // } else {
      //   dialogSelectProductTableVisible.value = true;
      // }
      dialogSelectLocationTableVisible.value = true;
    };

    async function getAddData() {
      await store
        .dispatch(Actions.CUST_GET_COMPANY_PAGE_DATA)
        .then(({ body }) => {
          nob_data.value = body.business_natures;
          group_type_data.value = body.group_types;
          group_type_data.value.splice(0, 1);
          business_types_data.value = body.business_types;
          roc_list_data.value = body.roc_list;
          roc_list_data.value.splice(0, 1);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    return {
      group_type_data,
      industry_types_data,
      business_types_data,
      roc_list_data,
      formData,
      nob_data,
      rules,
      submit,
      formRef,
      loading,
      addStockJournalVoucharRef,
      uom_data,
      incoterms_data,
      payment_term_data,
      logReason,
      transport_applicable_data,
      tableData,
      addProduct,
      myData,
      product_data,
      deleteRow,
      getIncoterms,
      getProductList,
      branch_data,
      inward_warehouse_data,
      outward_warehouse_data,
      Required,
      getWarehouseList,
      addressList,

      getCompanyList,
      getTableData,
      addressData,
      supplier_data,
      dialogSelectLocationTableVisible,
      multipleTableRef,
      multipleSelection,
      openDilogue,
      handleSelectionChange,
      onCancle,
      type_data,
      expeceted_delivery_data,
      getPaymentTerms,
      paginationData,
      reason_data,
      invoice_no_data,
      getInvoiceData,
    };
  },
});
